import React from 'react'
import Layout from '../components/layout-v2';


const Page = () => (

  <Layout title="Digital Marketing">
    <section>
      <h3>Paid Search</h3>
      {/*
      <div className="img-container text-center">
        <img className="img-responsive" src="/images/Professional-Services-LP-Diagram-2.png" alt="Professional Services"/>
      </div>

      <p className="section-subheading">Quantic Professional Services offers complete consulting for businesses of small, medium and large Fortune 500 corporations. Quantic Professional Service engagements starts with an assessment of your current IT environments, data storage, data backup and disaster recovery strategies.</p>
*/}
      <section>
        Paid search, or pay-per-click (PPC) advertising, typically refers to the “sponsored result” on the top or side of a search engine results page (SERP).
        You only pay when your ad is clicked. You can tailor your PPC ads to appear when specific search terms are entered, creating ads that are targeted to a
        particular audience.
        <br/>
        <br/>
        PPC ads are flexible, visible, and most importantly, effective for many different types of organizations. They are also contextual advertisements—ads
        that appear when a person is searching for a particular keyword or term.
      </section>
      <section>
        <p>Advertising has evolved. No longer is it restricted to print publications, static billboards, radio, and television. Modern technologies have opened
          the door to a whole new&nbsp;era of advertising -<em>digital</em> advertising.&nbsp;Digital advertising allows marketers and advertisers to reach and
          appeal to&nbsp;their core audiences in new ways and with more precision.</p>
        <p>The challenge of meeting the modern buyer's expectation of a continuous, cross-channel, and personal experience is met with new ad&nbsp;technology
          and innovations that continue to advance at break-neck&nbsp;speeds. New ad technology&nbsp;platforms, types of ads, methods of&nbsp;tracking, dynamic
          ad content, and&nbsp;advances such as the Internet of&nbsp;Things now provide endless&nbsp;opportunities for marketers and&nbsp;advertisers to engage
          their customers personally and across channels.</p>
        <p>In this comprehensive, 110+ page guide, we cover topics from the evolution of digital advertising, to how to structure your <a
          href="digital-marketing/">digital marketing</a> team, to testing and optimization. Loaded with checklists, charts, and thought leadership from digital
          advertising experts, The Definitive Guide to Digital Advertising will teach you how to create strategic and dynamic digital advertising.</p>
        <p><strong>Download The Definitive Guide To Digital Advertising to learn:</strong></p>
        <ul>
          <li>What makes up digital advertising</li>
          <li>How to create your digital ad strategy</li>
          <li>The different types of ads you have at your disposal</li>
          <li>The pricing structures you can expect</li>
          <li>How to target your digital ads</li>
          <li>How to design your digital ads</li>
          <li>The types of ad technology that are available</li>
          <li>How to test and measure your ads</li>
          <li>Insights from industry experts</li>
        </ul>

      </section>
      <br/>
      <h3>Search Engine Optimization</h3>
      <section>
        <p>
          Simply put, search engine optimization (SEO) is the process of optimizing the content, technical set-up, and reach of your website so that your pages
          appear at the top of a search engine result for a specific set of keyword terms. Ultimately, the goal is to attract visitors to your website when they
          search for products, services, or information related to your business.
        </p>
        <p>
          SEO can almost be viewed as a set of best practices for good digital marketing. It enforces the need for a well-constructed and easy-to-use website,
          valuable and engaging content, and the credibility for other websites and individuals to recommend you by linking to your site or mentioning it in
          social media posts.
        </p>
      </section>

      <br/>
      <h3>Content Marketing</h3>
      <section>
        <p>Have you heard the expression, “Content is king?” If not, you have now. Great content is the fuel that drives your digital marketing activities:</p>
        <ul>
          <li>It is a key pillar of modern SEO</li>
          <li>It helps you get noticed on social media</li>
          <li>It gives you something of value to offer customers in emails and paid search ads</li>
        </ul>
        <p>Creating clever content that is not promotional in nature, but instead educates and inspires, is tough but well worth the effort. Offering content
          that is relevant to your audience helps them see you as a valuable source of information. On top of that, resourceful content makes it less likely
          that they will tune you out.</p>
      </section>

      <br/>
      <h3>Social Media Marketing</h3>
      <section>
        <p>People aren’t just watching cat videos and posting selfies on social media these days. Many rely on social networks to discover, research, and
          educate themselves about a brand before engaging with that organization. For marketers, it’s not enough to just post on your Facebook and Twitter
          accounts. You must also weave social elements into every aspect of your marketing and create more peer-to-peer sharing opportunities. The more your
          audience wants to engage with your content, the more likely it is that they will want to share it. This ultimately leads to them becoming a customer.
          And as an added bonus, they will hopefully influence their friends to become customers, too.</p>
      </section>

      <br/>
      <h3>Email Marketing</h3>
      <section>
        <p>Email has been around for more than two decades, and it’s not going anywhere anytime soon. It’s still the quickest and most direct way to reach
          customers with critical information. The reason is simple: Consumers are very attached to their emails. Just ask yourself how many times you have
          checked your email in the past hour...See what we mean?</p>
        <p>But great marketers know that not just any email will do. Successful email campaigns must be engaging, relevant, informative, and entertaining. To
          succeed, your marketing emails should satisfy these five core attributes:</p>
        <ul>
          <li>Trustworthy</li>
          <li>Relevant</li>
          <li>Conversational</li>
          <li>Be coordinated across channels</li>
          <li>Strategic&nbsp;</li>
        </ul>
      </section>


      <br/>
      <h3>Mobile Marketing</h3>
      <section>
        <p>As mobile devices become an increasingly integral part of our lives, it’s vital that marketers understand how to effectively communicate on this
          unique and extremely personal channel. Mobile devices are kept in our pockets, sit next to our beds, and are checked constantly throughout the day.
          This makes marketing on mobile incredibly important but also very nuanced.</p>
        <p>
          From SMS and MMS to in-app marketing, there are many ways to market on mobile devices, so finding the right method for your particular business is
          key. Beyond the mechanisms to deliver your message, you also need to think about coordination of your marketing across digital channels and make sure
          mobile is a part of that mix.</p>
      </section>

      <br/>
      <h3>Marketing Automation</h3>
      <section>
        <p>Marketing automation is an integral platform that ties all of your digital marketing together. Without it, your campaigns will look like an
          unfinished puzzle with a crucial missing piece. Marketing automation software streamlines and automates marketing tasks and workflows. Most
          importantly, it measures the results and ROI of your digital campaigns, helping you to grow revenue faster.</p>
        <p>
          When used effectively, marketing automation will help you gain much-needed insight into which programs are working and which aren’t. It will give you
          the metrics needed to speak confidently about digital marketing’s impact on the bottom line.</p>
      </section>


    </section>
    <br/>
  </Layout>
);

export default Page;
